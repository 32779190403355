<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel">
    <v-form ref="form" class="pa-6 text-start">
      <div class="d-flex justify-space-between mb-8 align-center">
        <div
          class="align-self-center font-weight-medium"
          :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'"
        >
          Add New Pool
        </div>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </div>
      <div>
        Token *
        <v-text-field
          :disabled="loading"
          placeholder="Enter token address"
          outlined
          dense
          v-model="tokenAddress"
          class="mt-1 rounded-xl text-body-2"
          :rules="[$rules.required, $rules.contractAddress]"
        >
        </v-text-field>
      </div>
      <div class="text-body-2">
        Pool name *
        <v-text-field
          :disabled="loading"
          placeholder="Enter title for pool"
          outlined
          dense
          v-model="name"
          class="mt-1 rounded-xl text-body-2"
          :rules="[$rules.required]"
        >
        </v-text-field>
      </div>
      <div class="text-body-2">
        Description
        <v-textarea
          :disabled="loading"
          placeholder="A small description of pool"
          outlined
          class="rounded-xl mt-1 text-body-2"
          v-model="description"
        >
        </v-textarea>
      </div>
      <v-row>
        <v-col cols="9" sm="5" class="pb-0">
          <p class="text-body-2 mb-1">Date *</p>
          <v-text-field
            :disabled="loading"
            v-model="activeDate"
            type="date"
            :rules="[$rules.required, $rules.date]"
            dense
            outlined
            rounded
            @input="dateError = false"
          />
        </v-col>
        <v-col cols="9" sm="5" class="pb-0">
          <p class="text-body-2 mb-2">Hour *</p>
          <v-text-field
            v-model="activeHour"
            type="time"
            :rules="[$rules.required]"
            dense
            outlined
            rounded
            @input="dateError = false"
          />
        </v-col>
      </v-row>
      <div class="red--text" v-if="dateError">Must be the future time</div>
      <div class="d-flex justify-space-between mt-2">
        <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
        <v-btn class="primary" rounded min-width="100px" @click="nextStep" :loading="addClaimLoading">Next</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
export default {
  props: {
    state: Boolean,
  },
  components: {},
  computed: {
    ...mapState('auth', ['account', 'multiClaimHandler']),
    ...mapState('claimPools', ['account', 'addClaimLoading']),
  },
  methods: {
    ...mapActions('claimInfo', ['updateClaimInfo']),
    ...mapActions('claimPools', ['addClaim', 'resetClaimInfo']),
    async nextStep() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const activeTime = Date.parse(new Date(this.activeDate + ' ' + this.activeHour)) / 1000
        if (moment(activeTime * 1000).isBefore(moment())) {
          this.dateError = true
          return
        }
        const claimInfo = {
          tokenAddress: this.tokenAddress,
          name: this.name,
          description: this.description,
          activeDate: activeTime,
        }
        const res = await this.addClaim({ multiClaimHandler: this.multiClaimHandler, claimInfo })
        if (res) {
          this.$alert.success('Add pool successed')
          this.$emit('addClaimDone')
          this.dialog = false
        } else {
          this.$alert.error('Add pool failed')
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async resetDefault() {
      if (this.$refs.form) {
        await this.$refs.form.reset()
      }
      this.activeDate = ''
      this.activeHour = ''
      this.tokenAddress = ''
      this.name = ''
      this.description = ''
      this.dateError = false
    },
  },
  async created() {
    await this.resetClaimInfo()
  },
  data() {
    return {
      activeDate: '',
      activeHour: '',
      tokenAddress: '',
      name: '',
      description: '',
      dialog: false,
      loading: false,
      dateError: false,
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>
<style scoped>
</style>